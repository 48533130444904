<template>
  <v-app>
    <router-view name="appBar"></router-view>
    <v-main>
      <v-sheet
        style="min-height: calc(100vh - 128px); max-height: calc(100vh - 128px)"
      >
        <template
          class="overflow-hidden block-swipe-nav"
          style="position: relative"
        >
          <router-view name="default"></router-view>
        </template>
      </v-sheet>
    </v-main>
    <router-view name="leftDrawer"></router-view>
    <router-view name="rightDrawer"></router-view>
    <router-view name="bottomNavigation"></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      menuPage: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    this.$store.dispatch("bindDocs");
    //this.$store.dispatch("bindCleaningData");
    // const element = document.querySelector(".block-swipe-nav");

    // element.addEventListener("touchstart", (e) => {
    //   // is not near edge of view, exit
    //   if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;

    //   // prevent swipe to navigate gesture
    //   e.preventDefault();
    // });
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    getDimensions() {
      let scale = {
        transform:
          "translate(-50%, -51%) scale(" +
          Math.min(
            document.documentElement.clientWidth / 600,
            (document.documentElement.clientHeight - 80) / 1000
          ) +
          ")",
      };
      this.$store.commit("updateScale", scale);
    },
  },
};
</script>
<style>
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .appBar {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
}
</style>
