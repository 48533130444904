<template>
  <v-sheet height="100vh" class="overflow-hidden" style="position: relative">
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-card width="400" height="200">
          <v-row class="my-3">
            <v-img
              :src="require('../assets/logo.png')"
              class="my-3"
              contain
              height="100"
            />
          </v-row>
          <v-row style="position: relative" align="center" justify="center">
            <v-btn color="green darken-4" dark @click="googleSignIn">
              Login
            </v-btn>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
//import "firebase/firestore";
//import { GoogleAuthProvider } from "firebase/auth";

//const provider = new GoogleAuthProvider();

export default {
  name: "SignUp",
  methods: {
    googleSignIn: function () {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          let token = result.credential.accessToken;
          let user = result.user;
          console.log(token); // Token
          console.log(user); // User that was authenticated
          this.$router.push("/calendar");
        })
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
        });
    },
  },
};
</script>
