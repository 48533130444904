import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBKeJ1gjxMs238eyHE0upcpoWxnVK6gBlk",
  authDomain: "core.sanctumretreat.ca",
  projectId: "august-enigma-330501",
  storageBucket: "august-enigma-330501.appspot.com",
  messagingSenderId: "190680544116",
  appId: "1:190680544116:web:c5d7c23c8b3c8827ef7221",
  measurementId: "G-P3CMHR6VP7",
};
// Initialize Firebase
const { Timestamp, GeoPoint, FieldPath } = firebase.firestore;
export { Timestamp, GeoPoint, FieldPath };
export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log("Your token is:", token);
    return token;
  } catch (error) {
    console.error(error);
  }
};
