import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  theme: {
    sanctumGreen: "#013A01",
  },
});

export default new Vuetify({});
